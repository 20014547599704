<template>
  <WsMain v-if="isMounted">
    <WsState
      v-if="$config.wsmodule.user.invite.general.invited_shop_deduct_rate"
      type="number"
      :label="$t('受邀者商城折數')"
      v-model="state.content.invited_shop_deduct_rate"
    ></WsState>
    <WsState
      v-if="$config.wsmodule.user.invite.general.invited_shop_deduct"
      type="number"
      :label="$t('受邀者商城折抵')"
      v-model="state.content.invited_shop_deduct"
    ></WsState>
    <WsState
      type="number"
      :label="$t('邀請人紅利回饋')"
      v-model="state.content.invite_feedback_bonus_points"
    ></WsState>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
    <WsPopup
      max-width="720"
      ref="submitSuccessPopup"
    >
      <template v-slot:content>
        <slot name="content">
          內容已儲存
        </slot>
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="submitSuccessPopupClose()"
          :minWidth="100"
          :loading="loading.submit"
        >確定</WsBtn>
      </template>
    </WsPopup>
  </WsMain>
</template>

<script>
import S_API_GeneralUserInvite from "@/__vue2stone_cms/service/api/v1/general_user_invite";
export default {
  data() {
    return {
      isMounted: false,
      state: {
        content: {
          invited_shop_deduct: "",
          invited_shop_deduct_rate: "",
          invite_feedback_bonus_points: "",
        },
      },
      loading: {
        submit: false,
      },
    };
  },
  methods: {
    async $_onSubmit() {
      this.loading.submit = true;
      const postData = {
        ...this.state,
      };
      postData.content = btoa(
        unescape(encodeURIComponent(JSON.stringify(postData.content)))
      );
      try {
        await S_API_GeneralUserInvite.update(postData);
        this.submitSuccessPopupOpen();
      } catch (err) {
        console.error(err);
      } finally {
        this.loading.submit = false;
      }
    },
    async $_valueSet() {
      const res = await S_API_GeneralUserInvite.show();
      if (res.content) {
        if (res.content.invited_shop_deduct_rate) {
          this.state.content.invited_shop_deduct_rate =
            res.content.invited_shop_deduct_rate;
        }
        if (res.content.invite_feedback_bonus_points) {
          this.state.content.invite_feedback_bonus_points =
            res.content.invite_feedback_bonus_points;
        }
        if (res.content.invited_shop_deduct) {
          this.state.content.invited_shop_deduct =
            res.content.invited_shop_deduct;
        }
      }
      this.isMounted = true;
    },
    submitSuccessPopupOpen() {
      this.$refs.submitSuccessPopup.open();
    },
    submitSuccessPopupClose() {
      this.$refs.submitSuccessPopup.close();
    },
  },
  mounted() {
    this.$_valueSet();
  },
};
</script>

<style>
</style>